<template>
  <div class="mt-6 mb-16 flex gap-4 items-center justify-end">
    <button v-if="!noOptions" class="actions-dropdown relative">
      <div class="m-auto flex text-base items-center justify-between actions-btn">
        <span class="mr-2" id="pageSpan">{{maximumItems + $t(' / page')}}</span>
        <feather-icon id="pageIcon" class="w-5 h-5" icon="ChevronDownIcon"></feather-icon>
      </div>
      <div class="dropdown-list hidden-list" id="paginationList">
        <button @click="changePageSize(item)" v-for="item in pageSizes" :key="item.index">
          <span>{{ item }}</span>
        </button>
      </div>
    </button>
    <div class="flex gap-2">
      <button @click="changePageNumber('previous')" :disabled="!paginationObj.previous" :class="`flex items-center justify-center rounded-full border-0 cursor-pointer w-10 h-10 ${paginationObj.previous ? 'text-grey-dark hover:bg-primary hover:text-white' : 'text-grey'}`">
        <span class="material-symbols-outlined text-lg">{{ $route.params.lang === 'en' ? 'chevron_left' : 'chevron_right' }}</span>
      </button>
      <p v-if="paginationObj.currentPage" class="flex items-center justify-center rounded-full bg-primary text-white h-10 p-2" style="min-width: 35px;">{{ paginationObj.currentPage }}</p>
      <button @click="changePageNumber('next')" :disabled="!paginationObj.next" :class="`flex items-center justify-center rounded-full border-0 cursor-pointer w-10 h-10 ${paginationObj.next ? 'text-grey-dark hover:bg-primary hover:text-white' : 'text-grey'}`">
        <span class="material-symbols-outlined text-lg">{{ $route.params.lang === 'en' ? 'chevron_right' : 'chevron_left'}}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['noOptions', 'maximumItems', 'paginationObj'],
  data () {
    return {
      pageSizes: [10, 25, 50]
    }
  },
  methods: {
    changePageSize (val) {
      this.$emit('changePageSize', val)
    },
    changePageNumber (type) {
      this.$emit('changePageNumber', type)
    }
  }
}
</script>