<template>
  <div id="merchant-exchanges">
    <div class="vx-row mb-4">
      <div class="vx-col w-full lg:w-1/2 mb-4">
        <h2>{{$t('Exchanges')}}</h2>
      </div>
      <div class="vx-col w-full lg:w-1/2 items-end">
        <vs-alert class="mb-base" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="merchantStatus === 'on_hold'">
          {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
        </vs-alert>
        <div v-else-if="$store.state.AppActiveUser.userRole === 'merchant' && merchantStatus === 'active'">
          <div class="flex justify-between">
            <span class="font-semibold"></span>
            <span @click="exchangePage()" class="font-medium text-primary cursor-pointer">
              <vs-button
                color="primary"
                icon-pack="feather"
                icon="icon-plus"
                size="large"
              >
              </vs-button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event"></head-component>
    <shipblu-table
      :sst="true"
      multiple
      orders
      v-model="selected"
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      pagination
      :data="exchanges"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Order Type')}}</shipblu-th>
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Created')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-exchanges`, params: { type: 'exchanges', orderID: data[indextr].id }}">
          <shipblu-td class="order-3" :data="data[indextr].orderType">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Order Type')}}</p>
            <p  class="mb-3">Return</p>
            <p>Delivery</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="order-4 sm:pointer-events-none">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Tracking No.')}}</p>
            <p v-on:click.stop class="mb-2">{{data[indextr].return_order.tracking_number}}</p>
            <p v-on:click.stop>{{data[indextr].delivery_order.tracking_number}}</p>
          </shipblu-td>

          <shipblu-td class="order-5 sm:pointer-events-none" :data="data[indextr].customerInfo">
            <div v-on:click.stop>
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer')}}</p>
              <p class="sm:text-base text-sm">{{ data[indextr].delivery_order.customer.full_name }}</p>
              <p class="text-sm flex gap-1 sm:text-secondary sm:mt-2 mt-1">
                {{ data[indextr].delivery_order.customer.phone }}
                <span @click="copied(data[indextr].delivery_order.customer.phone)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].location">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Location')}}</p>
            <p class="sm:text-base text-sm">{{ $t(data[indextr].delivery_order.customer.address.zone.name) }}</p>
            <p class="text-sm sm:text-secondary sm:mt-2 mt-1">{{ $t(data[indextr].delivery_order.customer.address.zone.city.name) }}</p>
          </shipblu-td>

          <shipblu-td class="order-2" :data="data[indextr].createdOn">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created')}}</p>
            {{ common.splitYearOfDate(data[indextr].created) }}
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].status" style="white-space: nowrap;">
            <p v-if="getExchangeStatusLabel(data[indextr])" class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div v-if="getExchangeStatusLabel(data[indextr])" class="flex" style="width: fit-content;">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getExchangeStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationObj" @changePageSize="changePageSize" @changePageNumber="changePageNumber($event)" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import HeadComponent from '../merchant/components/Header.vue'
import Shipment from './components/Shipment.vue'
import ShipbluCursorPagination from '../../layouts/components/ShipbluCursorPagination.vue'
import i18nData from '../../i18n/i18nData.js'

export default {
  data () {
    return {
      common,
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      exchanges: [],
      filters: [],
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'created',
          value: 'delivery_order_status=created,pickup_requested,out_for_pickup&return_order_status=created,return_requested'
        },
        {
          name: 'picked up to exchange',
          value: 'delivery_order_status=picked_up,in_transit,en_route,out_for_delivery,delivery_attempted&return_order_status=created,return_requested,return_attempted'
        },
        {
          name: 'swapping',
          value: 'delivery_order_status=delivered&return_order_status=picked_up,in_transit,en_route,return_postponed,return_refused'
        },
        {
          name: 'exchanged',
          value: 'delivery_order_status=delivered&return_order_status=returned'
        },
        {
          name: 'failed to exchange',
          value: 'delivery_order_status=cancelled,delivery_attempted&return_order_status=cancelled,return_refused'
        }
      ],
      searchVal: '',
      filter: '',
      paginationObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false,
      merchantStatus: ''
    }
  },
  watch: {
    'selected' () {
      this.$emit('selected', this.selected)
    }
  },
  methods: {
    async copied (data) {
      try {
        await navigator.clipboard.writeText(data)
      } catch ($e) {
        this.$vs.notify({
          color:'dark',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    },
    changePageNumber (val) {
      this.loadExchanges(this.paginationObj[val])
      this.paginationObj.currentPage = val === 'next' ? this.paginationObj.currentPage + 1 : this.paginationObj.currentPage - 1
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.loadExchanges()
    },
    exchangePage () {
      this.$router.push({
        name: 'merchant-shipments',
        query: {
          filter: 'delivered',
          from: 'exchange'
        } 
      }).catch(() => {})
    },
    getExchangeStatusLabel (order) {
      return common.getExchangeStatusLabel(order)
    },
    getStyle (data) {
      return common.getOrderStatusColor(this.getExchangeStatusLabel(data) ? this.getExchangeStatusLabel(data) : '')
    },
    changeFilter () {
      this.filters = ''
      this.filters = this.filterValue
      if (this.filterValue === 'all') {
        this.filters = ''
      }
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-exchanges-view`,
        query: {
          filter: this.filters
        }
      }).catch(() => {})
      this.loadExchanges()
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadExchanges()
    },
    loadExchanges (cursorVal) {
      if (!cursorVal) this.paginationObj.currentPage = 1
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}&search=${this.searchVal}&${this.filters}`
      sendRequest(true, false, this, `api/v1/exchanges/${query}`, 'get', null, true, 
        (response) => {
          this.exchanges = response.data.results
          this.paginationObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    viewExchange (data) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-exchanges`,
        params: {
          type: 'exchanges',
          orderID: data.id
        }
      }).catch(() => {})
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    HeadComponent,
    Shipment,
    ShipbluCursorPagination
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : ''
    if (this.$store.state.AppActiveUser.userRole === 'merchant') {
      const merchantData = common.checkMerchantInLocalStorage(this)
      let user = {}
      merchantData.then(results => {
        user = results.merchantData
        this.merchantStatus = user.status
      })
    }
    this.loadExchanges()
  }
}
</script>

<style>
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
