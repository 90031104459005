<template>
  <div>
    <vs-alert class="mb-base" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="user.status === 'on_hold'">
      {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
    </vs-alert>
    <div class="flex justify-between">
      <span class="font-semibold"></span>
      <span v-show="$route.name !== 'merchant-cash-collections-view'" class="font-medium text-primary cursor-pointer">
        <vs-dropdown v-show="user.status == 'active'"
          vs-trigger-click
          ref="card"
          class="dropdown dd-actions cursor-pointer"
          :class="tourClosed ? 'step-selector' : ''"
        >
          <vs-button
              color="primary"
              class="px-4 py-3 rounded-lg"
              >
            <div class="flex items-center">
              <span class="text-base font-medium leading-tight">{{$t('Create Order')}}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-2 mt-1"/>
            </div>
          </vs-button>
          <vs-dropdown-menu class="add-shipment-dropdown w-64">
            <vs-dropdown-item class="step-selector-2" @click="redirectToCreateOrder('delivery-orders')">
              <span class="flex items-center text-blue-900 hover:text-primary">
                <feather-icon
                  icon="TruckIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{$t('Delivery Order')}}</span>
              </span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="redirectToCreateOrder('returns')">
                <span class="flex items-center text-blue-900 hover:text-primary">
                  <feather-icon
                    icon="PackageIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>{{$t('Return Order')}}</span>
                </span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="redirectToCreateOrder('exchanges')">
              <span class="flex items-center">
                <feather-icon
                  icon="RepeatIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{$t('Exchange Order')}}</span>
              </span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="redirectToCreateOrder('cash-collections')">
              <span class="flex items-center">
                <feather-icon
                  icon="DollarSignIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{$t('Cash Collection Order')}}</span>
              </span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="uploadShipments()">
              <span class="flex items-center">
                <feather-icon
                  icon="UploadIcon"
                  svgClasses="h-4 w-4"
                  class="mr-2"
                />
                <span>{{$t('Multiple Orders')}}</span>
              </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </span>
      <span v-show="$route.name === 'merchant-cash-collections-view' && user.status === 'active'" class="font-medium text-primary cursor-pointer">
        <span @click="redirectToCreateOrder('cash-collections')" class="font-medium text-primary cursor-pointer">
          <vs-button
            color="primary"
            icon-pack="feather"
            icon="icon-plus"
            size="large"
          >
          </vs-button>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tour', 'tourClosed', 'user'],
  watch: {
    'tourClosed' () {
      this.$emit('tour', '')
    },
    'tour' () {
      if (document.querySelector('.step-selector')) {
        document.querySelector('.step-selector').addEventListener('click', () => {
        })
      }
    }
  },
  methods: {
    uploadShipments () {
      this.$router.push({
        name: 'upload-order'
      }).catch(() => {})
    },
    redirectToCreateOrder (tab) {
      this.$router.push({
        name: 'merchant-create-order',
        query: {
          tab
        }
      }).catch(() => {})
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";

.vs-con-input-label.is-label-placeholder {
  margin-top: 10px;
}
.dropdown {
  .vs-dropdown--item-link span {
    color: #0A3266 !important;
  }
}
.new-img .vs-dropdown--item-link{
  position: unset;
}
[dir=rtl] .shepherd-modal-overlay-container {
  right: -5px !important;
}
</style>
