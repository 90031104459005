
<template>
  <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between main">
    <div class="flex flex-wrap items-center data-list-btn-container">
      <vs-dropdown v-if="!noActions" vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">
        <div class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-full">
          <span class="mr-2">{{$t('Actions')}}</span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
        </div>
        <vs-dropdown-menu  class="ml-8 dropdown-menu">
          <vs-dropdown-item :disabled="$route.name.includes('exchanges')" @click="printLabels()">
            <span class="flex items-center">
              <feather-icon
                icon="PrinterIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              <span>{{$t('Print Label')}}</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item v-if="$store.state.AppActiveUser.userRole === 'merchant'" @click="requestPickup()">
            <span class="flex items-center">
              <feather-icon
                icon="TruckIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              <span>{{$route.name === 'merchant-returns-view' ? $t('Request Return') : $route.name === 'merchant-cash-collections-view'? $t('Collect Request') :  $t('Request Pickup')}}</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item v-if="selected" @click="downloadShipments()">
            <span class="flex items-center">
              <feather-icon
                icon="DownloadIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              <span>{{$t('Download')}}</span>
            </span>
          </vs-dropdown-item>
          <vs-dropdown-item v-if="selected && ($store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'merchant' || $store.state.AppActiveUser.userRole === 'customer-support')" @click="confirmCancel()">
            <span class="flex items-center">
              <feather-icon
                icon="XCircleIcon"
                svgClasses="h-4 w-4"
                class="mr-2"
              />
              <span>{{$t('Cancel Shipments')}}</span>
            </span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
      <template v-if="$route.name.includes('exchanges-view')">
        <div class="relative m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
          v-for="status in statusFilters" :key="status.index" @click="changeFilter(status.value)"
          v-bind:style="(!$route.query.filter || $route.query.filter === '') && status.value === 'all' ? `background:${status.color ? status.color : '#1C5BFE'}; color: white` : $route.query.filter && $route.query.filter === status.value  ? `${getstyle(status.value)}; color: #fff;` : 'color: #6C84A3;'">
          <div class="text-sm capitalize">{{ $t(status.name) }}</div>
          <div v-if="status.value !== 'all'" class="absolute right-0 top-0 w-2 h-full rounded-4xl" :style="getstyle(status.value)"></div>
        </div>
      </template>
      <template v-else>
        <div class="relative m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
          v-for="status in statusFilters" :key="status.index" @click="changeFilter(status.value ? status.value : status.id)"
          v-bind:style="(!$route.query.filter || $route.query.filter.length === 0) && status.value === 'all' ? `background:${status.color ? status.color : '#1C5BFE'}; color: white` : $route.query.filter && ($route.query.filter.includes(status.value) || String($route.query.filter) === String(status.id)) ? `${getstyle(status.value)}; color: #fff;` : 'color: #6C84A3;'">
          <div class="text-sm capitalize">{{ $t(status.name) }}</div>
          <div class="text-sm capitalize text-center" v-if="status.amount">{{ $t(status.amount) }}</div>
          <div v-if="status.value !== 'all'" class="absolute right-0 top-0 w-2 h-full rounded-4xl" :style="getstyle(status.value)"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import common from '@/assets/utils/common'
import i18nData from '../../../i18n/i18nData.js'

export default {
  props: ['statusFilters', 'selected', 'noActions'],
  data () {
    return {
      headerTitle: ['Tracking Number', 'Created', 'Merchant Name', 'Customer Name', 'Customer Zone', 'Customer City', 'CoD', 'Amount', 'Status'],
      headerVal: ['tracking_number', 'created', 'merchant', 'customer', 'customerZone', 'customerCity', 'cash_processing', 'cash_amount', 'status'],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      },
      disabledDates: {
        to: new Date() // Disable all dates up to specific date
      },
      disabledDatesStart: {
        from: new Date() // Disable all dates up to specific date
      },
      startDate: '',
      endDate: '',
      type: '',
      types: [
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'picked up',
          value: 'picked_up'
        },
        {
          name: 'completed',
          value: 'completed'
        }
      ],
      filters: [],
      localStorage
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  watch: {
    'startDate' () {
      const date = new Date(this.startDate)
      this.disabledDates.to = date
      const endDate = new Date()
      this.disabledDates.from = this.$store.state.AppActiveUser.userRole === 'merchant' ? new Date(endDate.setDate(date.getDate() + 45)) : new Date(endDate.setDate(date.getDate() + 7))
    }
  },
  components: {
    Datepicker,
    vSelect
  },
  methods: {
    getstyle (status) {
      return `background: ${common.getOrderStatusColor(status)}`
    },
    getOrderStatusColor (status) {
      return common.getOrderStatusColor(status)
    },
    confirmCancel () {
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to cancel these orders?'],
        accept: this.cancelOrders
      })
    },
    cancelOrders () {
      const trackings  = []
      this.selected.forEach(order => {
        if (order.status === 'created' || order.status === 'pickup_requested') {
          trackings.push({
            order: order.id,
            status: 'cancelled'
          })
        }
      })
      sendRequest(false, false, this, 'api/v1/tracking/', 'post', trackings, true,
        () => {
          this.$emit('loadShipments')
        }
      )
    },
    changeFilter (type) {
      this.$emit('filterValue', type)
      this.$emit('changeFilter')
    },
    downloadShipments () {
      import('@/vendor/Export2Excel').then(excel => {
        const list = this.selected
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: 'Shipments',
          autoWidth: true
        })
      })
    },
    requestPickup () {
      const trackings = []
      let url = ''
      this.selected.forEach(element => {
        if (element.status === 'created' || element.status === 'pickup_rescheduled') {
          if (this.$route.name === 'merchant-cash-collections-view') {
            url = 'api/v1/tracking/cash-collections/'
            trackings.push({
              cash_collection_request:element.id,
              status: 'collection_requested'
            })
          } else {
            url = 'api/v1/tracking/'
            trackings.push({
              order:element.id,
              status: this.$route.name === 'merchant-returns-view' ? 'return_requested' : 'pickup_requested'
            })
          }

        }
        if (this.$route.name === 'merchant-cash-collections-view') {
          url = 'api/v1/tracking/cash-collections/'
        }
      })
      sendRequest(false, false, this, url, 'post', trackings, true,
        () => {
          this.$emit('loadShipments')
        }
      )
      this.$emit('loadShipments')
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        switch (j) {
        case 'created':
          return new Date(v[j]).toLocaleDateString('fr-CA')
        case 'customer':
          return `${v[j].first_name} ${v[j].last_name}`
        case 'merchant':
          return `${v[j].name}`
        case 'customerCity':
          j = 'customer'
          return `${v[j].address.zone.city.name}`
        case 'customerZone':
          j = 'customer'
          return `${v[j].address.zone.name}`
        case 'cash_processing':
          if (v[j]) return 'Yes'
          else return 'No'
        }
        return v[j]
      }))
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>
<style scoped>
.main {
  background: rgba(220, 238, 255, 0.4);
  border-radius: 6px;
  padding: 8px;
}
</style>